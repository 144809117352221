import { useState } from "react";
import { FaFacebookF, FaInstagram, FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import moment from "moment";
import { ContactModal } from "../pages/home/components/contact";

export const Footer = () => {
  const [modalContactOpen, setModalContactOpen] = useState<boolean>(false);

  return (
    <footer className="text-white py-10 px-8 lg:px-16 bg-[#131A35]">
      <div className="container mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-12 lg:mt-8">
          <div className="col-span-12 lg:col-span-5">
            <div className="lg:col-span-1 text-center lg:text-left">
              <p className="text-lg font-bold">Fale conosco:</p>
              <a
                href="mailto:contact@iatools.top"
                className="text-blue-300 hover:underline"
              >
                contact@iatools.top
              </a>
              {/*
              <div className="flex justify-center lg:justify-start space-x-4 mt-4">
                <Link to="" className="hover:text-slate-300">
                  <FaFacebookF size={24} />
                </Link>
                <Link to="" className="hover:text-slate-300">
                  <FaInstagram size={24} />
                </Link>
                <Link to="" className="hover:text-slate-300">
                  <FaWhatsapp size={24} />
                </Link>
              </div>
              */}
            </div>
          </div>

          <div className="col-span-12 lg:col-span-2 mt-8 lg:mt-0">
            <ul className="flex flex-col items-center lg:items-start">
              <li className="mb-4">
                <HashLink to="/#home" smooth className="hover:text-slate-300">
                  Inicio
                </HashLink>
              </li>
              <li className="mb-4">
                <HashLink to="/#sobre" className="hover:text-slate-300" smooth>
                  Sobre
                </HashLink>
              </li>
            </ul>
          </div>

          <div className="col-span-12 lg:col-span-2">
            <ul className="flex flex-col items-center lg:items-start">
              {/* 
              <li className="mb-4">
                <HashLink
                  to="/#avaliacoes"
                  smooth
                  className="hover:text-slate-300"
                >
                  Avaliações
                </HashLink>
              </li>
              */}
              <li className="mb-4">
                <button
                  className="hover:text-slate-300"
                  onClick={() => setModalContactOpen(true)}
                >
                  Contato
                </button>
              </li>
            </ul>
          </div>

          <div className="col-span-12 lg:col-span-3">
            <ul className="flex flex-col items-center lg:items-start">
              <li className="mb-4">
                <HashLink to="/#planos" smooth className="hover:text-slate-300">
                  Planos
                </HashLink>
              </li>
              <li className="mb-4">
                <Link
                  to="/politica-privacidade"
                  className="hover:text-slate-300"
                >
                  Política de Privacidade
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="text-center mt-10">
          <p className="text-sm">© {moment().format("YYYY")} IATools</p>
          <p className="text-center text-xs text-white">
            Todos os direitos reservados.
          </p>
        </div>
      </div>

      {modalContactOpen && (
        <ContactModal onClose={() => setModalContactOpen(false)} />
      )}
    </footer>
  );
};

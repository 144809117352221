import { MainLayout } from "../../components/layouts";
import { About } from "./components/about";
import { Hero } from "./components/hero";
import { Plans } from "./components/plans";
import { Reviews } from "./components/reviews";

export default function Home() {
  return (
    <MainLayout>
      <Hero />
      <div className="container mx-auto px-8 lg:px-10">
        <About />
        {/*<Reviews />*/}
      </div>
      <Plans />
    </MainLayout>
  );
}

import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { colors } from "../../../style/colors";

export const About = () => {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  return (
    <div id="sobre">
      <div className="mt-28" id="sobre">
        <div
          className="grid grid-cols-1 lg:grid-cols-12 gap-3"
          data-aos="fade-up"
        >
          <div className="col-span-6">
            <div className="flex items-center justify-center -mt-16">
              <img
                src="/images/banner-sobre.svg"
                alt="banner-conexão"
                className="lg:-ms-24"
              />
            </div>
          </div>

          <div className="col-span-6">
            <p className="text-4xl font-semibold">
              Conheça o <span style={{ color: colors.primary }}>IATools</span>
            </p>

            <div className="mt-10 text-lg">
              <p>
                <strong>IATools</strong> atua como HUB de serviços complementares para plataformas de automação, bots e inteligência artificial.
              </p>

              <p className="mt-8">
                E para começarmos, estamos disponibilizando nosso serviço de conexão com <strong>WhatsApp</strong> multi-device, fundamental chatbots, plataformas de CRM e outros.
              </p>

              <p className="mt-8">
                Nosso servidor é totalmente compatível com o <a rel="noreferrer" href="https://doc.evolution-api.com/v2/pt/get-started/introduction" target="_blank" style={{ color: colors.primary }}>EvolutionAPI</a>, ferramenta amplamente difundida no mercado e de fácil integração.
              </p>

              <p className="mt-8">
                Com o <strong>IATools</strong> você pode focar no que é mais importante! O seu negócio…
              </p>

              <p className="mt-8">
                Com inúmeras possibilidades de integração como: Construção de bot's de atendimento, agendamento de consultas, notificações e centenas de outras aplicações... Tudo muito simples através do Whatsapp com custo reduzido e de forma segura!
              </p>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

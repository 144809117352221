import { Link } from "react-router-dom";
import { colors } from "../../../style/colors";

export const Hero = () => {
  return (
    <div className="mt-8 lg:mt-16 bg-[#131A35]" id="home">
      <div className="container mx-auto px-8 lg:px-10">
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-3">
          <div className="col-span-6 mt-16">
            <div className="font-bold text-5xl">
              <p style={{ color: colors.secondary }}>API para integração com</p>
              <p className="mt-3 text-white">WHATSAPP</p>
            </div>
            <div className="mt-8 text-white font-semibold text-3xl">
              <p>Perfeito para sua empresa, software e</p>
              <p className="mt-1">programadores</p>
            </div>

            <div className="mt-8 text-white">
              <p>
                Use o nossos servidores para integrar o <span style={{ color: colors.secondary }}>WHATSAPP</span> em seus sistemas, plataformas e chatbots via REST API! 
              </p>
            </div>

            <div className="mt-8 mb-8">
              <p className="text-3xl" style={{ color: colors.secondary }}>
                <del>R$ 79,90</del>
              </p>
              <p className="mt-3 font-semibold text-5xl text-white">
                R$ 29,90<span className="text-base">/por mês</span>
              </p>
            </div>

            <div className="lg:mb-16">
              <Link
                to="https://portal.iatools.top/register"
                target="_blank"
                className="px-24 py-4 rounded-lg text-white font-semibold bg-[#2B65D6] hover:bg-[#3773ec] transition-colors duration-150"
              >
                Experimente
              </Link>
            </div>
          </div>
          <div className="col-span-6">
            <img src="/images/banner-home.svg" alt="banner" />
          </div>
        </div>
      </div>
    </div>
  );
};

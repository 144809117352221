import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import { HashLink } from "react-router-hash-link";
import { ContactModal } from "../pages/home/components/contact";

export const Header = () => {
  const [menuResponsive, setMenuResponsive] = useState<boolean>(false);
  const [isContatoOpen, setIsContatoOpen] = useState<boolean>(false);

  const handleClick = () => {
    setMenuResponsive((state) => !state);
  };

  const handleOpenContato = () => {
    setIsContatoOpen(true);
    setMenuResponsive(false);
  };

  const handleCloseContato = () => {
    setIsContatoOpen(false);
  };

  const closeMenuResponsive = () => {
    setMenuResponsive(false);
  };

  return (
    <>
      <div className="fixed top-0 left-0 w-full z-50 bg-[#131A35]">
        <div className="flex justify-between items-center px-5 xl:px-10 py-5 container mx-auto">
          <HashLink
            to="/#home"
            smooth
            className="cursor-pointer flex items-center"
            onClick={closeMenuResponsive}
          >
            <img src="/images/logo.svg" alt="logo" className="h-16 w-16" />
            <p className="text-3xl ms-4 mt-3 text-white font-semibold">
              IATools
            </p>
          </HashLink>
          <div
            className={`nav-links duration-500 xl:static absolute xl:min-h-fit min-h-[40vh] left-0 -mt-1 ${
              menuResponsive ? "top-full" : "top-[-600%]"
            } xl:w-auto w-full flex items-center justify-end text-end xl:justify-start xl:text-start px-5 shadow-xl xl:shadow-none text-white font-semibold bg-[#131A35]`}
          >
            <ul className="flex xl:flex-row flex-col xl:items-center justify-end gap-8 mt-3 container mx-auto px-1 md:px-5">
              <li className="mx-4 xl:mx-0">
                <HashLink
                  to="/#home"
                  smooth
                  className="hover:text-slate-300"
                  onClick={closeMenuResponsive}
                >
                  Início
                </HashLink>
              </li>
              {/* 
              <li className="mx-4 xl:mx-0">
                <HashLink
                  to="/#avaliacoes"
                  smooth
                  className="hover:text-slate-300"
                >
                  Avaliações
                </HashLink>
              </li>
                */}

              <li className="mx-4 xl:mx-0">
                <HashLink
                  to="/#planos"
                  smooth
                  className="hover:text-slate-300"
                  onClick={closeMenuResponsive}
                >
                  Planos
                </HashLink>
              </li>

              <li className="mx-4 xl:mx-0">
                <HashLink
                  to="/#sobre"
                  smooth
                  className="hover:text-slate-300"
                  onClick={closeMenuResponsive}
                >
                  Sobre
                </HashLink>
              </li>
              <li className="mx-4 xl:mx-0">
                <button
                  onClick={() => {
                    handleOpenContato();
                    closeMenuResponsive();
                  }}
                  className="hover:text-slate-300"
                >
                  Contato
                </button>
              </li>

              <li className="mx-4 xl:mx-0 mb-5 xl:mb-0 mt-3 xl:mt-0">
                <Link
                  to="https://portal.iatools.top/login"
                  target="_blank"
                  className="px-12 py-4 rounded-xl bg-transparent border border-[#00A6EB] hover:bg-[#00A6EB] text-[#00A6EB] hover:text-white transition-colors duration-150"
                >
                  Login
                </Link>
              </li>

              <li className="mx-4 xl:mx-0 xl:!-ms-4 mb-10 xl:mb-0">
                <Link
                  to="https://portal.iatools.top/register"
                  target="_blank"
                  className="px-6 py-4 rounded-xl bg-[#2B65D6] hover:bg-[#3773ec] transition-colors duration-150"
                >
                  Teste grátis
                </Link>
              </li>
            </ul>
          </div>

          <div className="flex xl:hidden items-center gap-6 mt-2">
            <div className="text-white p-3 rounded transition-all">
              {menuResponsive ? (
                <FaTimes
                  className="text-xl cursor-pointer menu"
                  onClick={handleClick}
                />
              ) : (
                <FaBars
                  className="text-xl cursor-pointer menu"
                  onClick={handleClick}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {isContatoOpen && <ContactModal onClose={handleCloseContato} />}
    </>
  );
};

import { Footer } from "../footer";
import { Header } from "../header";

interface Props {
  children: React.ReactNode;
}

export const MainLayout = ({ children }: Props) => {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="container mx-auto px-4">
        <Header />
      </div>

      <div className="flex flex-col grow">
        <main className="grow my-10">{children}</main>
      </div>
      <Footer />
    </div>
  );
};

import { Link } from "react-router-dom";
import { colors } from "../../../style/colors";

export const Plans = () => {
  return (
    <div
      className="text-white py-16 mt-20 bg-[#131A35] px-8 lg:px-10"
      id="planos"
    >
      <div className="container mx-auto">
        <h2 className="text-center text-[36px] font-bold mb-8">Planos</h2>
        <p className="text-center text-lg">
          Confira os planos para conexão com <strong>WhatsApp</strong> que
          oferecemos para transformar ainda mais o seu negócio com o{" "}
          <strong>IATools</strong>.
        </p>
        <div className="px-4 sm:px-8 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-8 mt-[60px]">
          <div className="bg-white text-center rounded-lg shadow-lg overflow-hidden flex flex-col">
            <div className="bg-orange-500 text-white py-4">
              <h3 className="text-2xl font-bold">Experimente</h3>
              <p className="text-[28px] lg:text-[36px] mt-4">GRATUITAMENTE</p>
            </div>
            <div className="p-6 text-gray-700 flex-grow">
              <ul>
                <li className="flex items-center justify-center mb-4">
                  Experimente por 48 horas
                </li>
                <li className="flex items-center justify-center mb-4">
                  Mensagens ilimitadas
                </li>
                <li className="flex items-center justify-center mb-4">
                  Até 5 dispositivos conectados por instância
                </li>
                <li className="flex items-center justify-center mb-4">
                  Envio e recebimento de arquivos
                </li>
                <li className="flex items-center justify-center mb-4">
                  Mensageria
                </li>
                <li className="flex items-center justify-center mb-4">
                  Webhooks
                </li>
                <li className="flex items-center justify-center mb-4">
                  Chatbots
                </li>
              </ul>
            </div>
          </div>

          <div className="bg-white text-center rounded-lg shadow-lg overflow-hidden flex flex-col">
            <div
              className="text-white py-4"
              style={{ background: colors.secondary }}
            >
              <h3 className="text-2xl font-bold">PROMOÇÃO DE LANÇAMENTO</h3>
              <div className="mt-4">
                <p className="text-lg line-through">R$ 79,90</p>
                <p className="text-[36px] font-bold text-white">R$ 29,90</p>
              </div>
              <p className="text-sm">ao mês</p>
            </div>
            <div className="p-6 text-gray-700 flex-grow">
              <ul>
                <li className="flex items-center justify-center mb-4">
                  Preço POR INSTÂNCIA
                </li>
                <li className="flex items-center justify-center mb-4">
                  Mensalidade de R$ 29,90/mês NOS PRIMEIROS 6 MESES!
                </li>
                <li className="flex items-center justify-center mb-4">
                  Após 6 meses, mensalidade passa a ser de R$ 49,90/mês, preço TAMBÉM promocional que será mantido enquanto a assinatura estiver
                  vigente!
                </li>
                <li className="flex items-center justify-center mb-4">
                  Todos os recursos disponíveis
                </li>
                <li className="flex items-center justify-center mb-4">
                  Sem pegadinhas
                </li>
              </ul>
            </div>
          </div>

          <div className="bg-white text-center rounded-lg shadow-lg overflow-hidden flex flex-col lg:col-span-2 xl:col-span-1 mx-auto w-full lg:w-[500px] xl:w-full">
            <div
              className=" text-white py-4"
              style={{ background: colors.primary }}
            >
              <h3 className="text-2xl font-bold">Grandes</h3>
              <h3 className="text-2xl font-bold">Volumes</h3>
            </div>
            <div className="p-6 text-gray-700 flex-grow">
              <ul>
                <li className="flex items-center justify-center mb-4">
                  Descontos exclusivos para contratação acima de 10 conexões!
                </li>
                <li className="flex items-center justify-center mb-4">
                  Entre em contato:
                </li>
                <li className="flex items-center justify-center mb-4">
                  contact@iatools.top
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-center text-center mt-12">
          <Link
            to="https://portal.iatools.top/register"
            target="_blank"
            className="px-6 py-4 rounded-lg text-white font-semibold w-72 bg-[#2B65D6] hover:bg-[#3773ec] transition-colors duration-150"
          >
            Experimente gratuitamente
          </Link>
        </div>
      </div>
    </div>
  );
};

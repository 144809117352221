import { useForm } from "react-hook-form";
import { colors } from "../../../style/colors";
import ContactRequestEmailArgs from "../../../interface/args/contact-request-email-args";
import { useState } from "react";
import api from "../../../services/api-client";
import ServiceResult from "../../../interface/service-result";
import toast from "react-hot-toast";
import apiErrorHandler from "../../../services/api-error-handler";

interface Props {
  onClose: () => void;
}

export const ContactModal = ({ onClose }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<ContactRequestEmailArgs>({
    defaultValues: {
      name: "",
      phone: "",
      email: "",
      subject: "",
      message: "",
    },
  });

  async function onSubmit(data: ContactRequestEmailArgs): Promise<void> {
    setLoading(true);

    const args: ContactRequestEmailArgs = {
      name: data.name,
      phone: data.phone,
      email: data.email,
      subject: data.subject,
      message: data.message,
    };

    api
      .post<ServiceResult>("/contactme", args)
      .then(() => {
        toast.success("Mensagem enviada com sucesso!");
        onClose();
      })
      .catch(apiErrorHandler)
      .finally(() => setLoading(false));
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div
        className="bg-white p-8 rounded-lg max-w-md w-full relative overflow-y-auto"
        style={{ maxHeight: "90vh" }}
      >
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-black"
        >
          <span className="text-4xl mx-2">&times;</span>
        </button>
        <h2 className="text-3xl font-bold mb-4">Fale conosco</h2>
        <p className="text-gray-600 mb-6">
          Entrando em contato através do formulário abaixo, você será contactado
          para saber maiores informações sobre nossos planos e possibilidades
          personalizadas para seu negócio.
        </p>
        <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <input
              type="text"
              placeholder="Nome *"
              className="w-full p-3 border rounded-md"
              {...register("name", {
                required: "O campo nome é obrigatório",
              })}
            />
            {errors.name && (
              <p className="text-red-500 text-sm">{errors.name.message}</p>
            )}
          </div>

          <div>
            <input
              type="email"
              id="email"
              placeholder="Email*"
              className="w-full p-3 border rounded-md"
              {...register("email", {
                required: "O campo e-mail é obrigatório",
              })}
            />
            {errors.email && (
              <p className="text-red-500 text-sm">{errors.email.message}</p>
            )}
          </div>

          <div>
            <input
              type="text"
              id="phone"
              placeholder="Telefone*"
              className="w-full p-3 border rounded-md"
              {...register("phone", {
                required: "O campo telefone é obrigatório",
              })}
            />
            {errors.phone && (
              <p className="text-red-500 text-sm">{errors.phone.message}</p>
            )}
          </div>

          <div>
            <input
              type="text"
              id="subject"
              placeholder="Assunto*"
              className="w-full p-3 border rounded-md"
              {...register("subject", {
                required: "O campo assunto é obrigatório",
              })}
            />
            {errors.subject && (
              <p className="text-red-500 text-sm">{errors.subject.message}</p>
            )}
          </div>

          <div>
            <textarea
              placeholder="Sua mensagem *"
              className="w-full p-3 border rounded-md resize-none"
              rows={4}
              {...register("message", {
                required: "O campo mensagem é obrigatório",
              })}
            />
            {errors.message && (
              <p className="text-red-500 text-sm">{errors.message.message}</p>
            )}
          </div>

          <button
            type="submit"
            className="w-full text-white p-3 rounded-md"
            style={{ background: colors.primary }}
            disabled={loading}
          >
            {loading ? "Enviando..." : "Enviar mensagem"}
          </button>

          <p className="text-center text-sm text-gray-400">
            - Os campos com * são de preenchimento obrigatório
          </p>
        </form>
      </div>
    </div>
  );
}

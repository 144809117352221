import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "../pages/home";
import NotFound from "../pages/not-found";
import PoliticaPrivacidade from "../pages/politica-privacidade";

export default function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/politica-privacidade" element={<PoliticaPrivacidade />} />

        {/* Not Found */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}
